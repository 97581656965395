var $class="se2--header-se--mega-menu",$name="HeaderSE/megaMenu",$path="app/components/HeaderSE/partials/megaMenu/index.js",$this={$class,$name,$path,};import {
  closeClassName,
  openClassName,
  menuItemClassName,
  menuNavButtonClassName,
  showClassName,
  closeDesktopMenuButtonSelector,
  level1ButtonPopupSelector,
  level1ItemSelector,
  level2ButtonSelector,
  menuType,
} from 'app/components/HeaderSE/partials/megaMenu/config';
import analytics from 'app/modules/analytics';

import shell from 'app/modules/shell';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import { isHeaderVersion8 } from 'app/components/HeaderSE/utilities/isHeaderVersion8';

import { css, messages } from 'configs';

import forEach from 'lodash/forEach';
import debounce from 'lodash/debounce';
import initializeSlider from 'app/components/utilities/initializeSlider';

export default shell.registerPartial($this, (
  { addEventListener, mount, publishMessage },
) => {
  function showLevel2MenuInMobile(l2btn) {
    l2btn.classList.toggle(css.classNames.hide);
    const { nextElementSibling } = l2btn;
    nextElementSibling?.firstElementChild.classList.toggle(showClassName);
    nextElementSibling?.classList.toggle(closeClassName);
  }

  mount((element) => {
    const menuNavButtons = element.querySelectorAll(`.${menuNavButtonClassName}`);
    const menuItems = element.querySelectorAll(`.${menuItemClassName}`);
    const level2Buttons = element.querySelectorAll(level2ButtonSelector);
    const closeDesktopMenuButtonElement = element.querySelector(`${closeDesktopMenuButtonSelector}`);
    const megaMenuL2items = element.querySelector('.navbar');
    const megaMenuL3items = element.querySelector('.submenu');
    const menuLevels = {};
    let menuItem3Index = null;
    let menuItem4Index = null;
    let menuItem5Index = null;
    const menuLevel1 = element.parentElement.parentElement.parentElement
      .querySelector('.mm-l1-button-popup-title').innerText.trim().replace(/\n/g, '');
    addEventListener(closeDesktopMenuButtonElement, 'click',
      () => publishMessage(messages.userClickedMegaMenuCloseButtonOnDesktop,
        { selector: closeDesktopMenuButtonSelector }));

    const handleGoogleAnalytics = () => {
      analytics.menuClickEvent(menuLevels);
    };

    // headerGAEventClick() is responsible for adding the GA event menu_click for L2, L3, L4 & L5
    // for header, analog to L1headerGAEventClick() which is for L1 header in commonPart index.js
    const headerGAEventClick = () => {
      if (isHeaderVersion8) {
        megaMenuL2items.querySelectorAll('.menu-nav-button')
          .forEach((menuL2LinkClass) => {
            addEventListener(menuL2LinkClass, 'click', (menuL2Event) => {
              menuLevels.menuType = menuType;
              const menuLevel2Click = menuL2Event.target.innerText.trim().replace(/\n/g, '');
              menuLevels.menuLevel1 = menuLevel1;
              menuLevels.menuLevel2 = menuLevel2Click;
              [menuLevels.menuLevel3, menuLevels.menuLevel4,
                menuLevels.menuLevel5] = Array(3).fill('');
              handleGoogleAnalytics(menuLevels);
            });
          });
        megaMenuL3items.querySelectorAll('.menu-item').forEach((menuL3Items, menuL3Index) => {
          addEventListener(menuL3Items?.querySelector('.se-mm-title-l3'), 'click', (e) => {
            menuLevels.menuType = menuType;
            if (!menuL3Items.classList.contains('hide')) {
              const menuLevel3Click = e.currentTarget.innerText;
              menuItem3Index = menuL3Index;
              menuLevels.menuLevel1 = menuLevel1;
              megaMenuL2items.querySelectorAll('.menu-nav-button').forEach((megaMenuL2itemsClass) => {
                if (megaMenuL2itemsClass.classList.contains('active')) {
                  if (menuLevels.menuLevel2 !== megaMenuL2itemsClass.innerText) {
                    menuLevels.menuLevel2 = '';
                  }
                }
              });
              if (menuItem4Index !== menuL3Index) {
                menuLevels.menuLevel4 = '';
              }
              if (menuItem5Index !== menuL3Index) {
                menuLevels.menuLevel5 = '';
              }
              menuLevels.menuLevel3 = menuLevel3Click;
              handleGoogleAnalytics(menuLevels);
            }
          });
          menuL3Items?.querySelectorAll('.se-mm-l4')?.forEach((menuL4LinkClass) => {
            addEventListener(menuL4LinkClass, 'click', (e) => {
              menuLevels.menuType = menuType;
              if (!menuL3Items.classList.contains('hide')) {
                const menuLevel4Click = e.currentTarget.innerText;
                const menuLevel4ClickValue = menuLevel4Click.replace('opens in new window', '');
                menuItem4Index = menuL3Index;
                menuLevels.menuLevel1 = menuLevel1;
                megaMenuL2items.querySelectorAll('.menu-nav-button').forEach((megaMenuL2itemsClass) => {
                  if (megaMenuL2itemsClass.classList.contains('active')) {
                    if (menuLevels.menuLevel2 !== megaMenuL2itemsClass.innerText) {
                      menuLevels.menuLevel2 = '';
                    }
                  }
                });
                if (menuItem3Index !== menuL3Index) {
                  menuLevels.menuLevel3 = '';
                }
                if (menuItem5Index !== menuL3Index) {
                  menuLevels.menuLevel5 = '';
                }
                menuLevels.menuLevel4 = menuLevel4ClickValue;
                handleGoogleAnalytics(menuLevels);
              }
            });
          });
          menuL3Items?.querySelectorAll('.se-mm-l5')?.forEach((menuL5LinkClass) => {
            addEventListener(menuL5LinkClass, 'click', (e) => {
              menuLevels.menuType = menuType;
              if (!menuL3Items.classList.contains('hide')) {
                const menuLevel5Click = e.currentTarget.innerText;
                const menuLevel5ClickValue = menuLevel5Click.replace('opens in new window', '');
                menuItem5Index = menuL3Index;
                menuLevels.menuLevel1 = menuLevel1;
                megaMenuL2items.querySelectorAll('.menu-nav-button').forEach((megaMenuL2itemsClass) => {
                  if (megaMenuL2itemsClass.classList.contains('active')) {
                    if (menuLevels.menuLevel2 !== megaMenuL2itemsClass.innerText) {
                      menuLevels.menuLevel2 = '';
                    }
                  }
                });
                if (menuItem4Index !== menuL3Index) {
                  menuLevels.menuLevel4 = '';
                }
                if (menuItem3Index !== menuL3Index) {
                  menuLevels.menuLevel3 = '';
                }
                menuLevels.menuLevel5 = menuLevel5ClickValue;
                handleGoogleAnalytics(menuLevels);
              }
            });
          });
        });
      }
    };

    let [activeMenuNavButton] = menuNavButtons;
    let [activeLevel2Item] = menuItems;
    headerGAEventClick();
    function initSliderMegaMenu() {
      if (!activeLevel2Item.dataset.sliderChecked && !isMobileBreakpoint()) {
        const sliderContainer = activeLevel2Item.querySelector('.mm-slider-container.level2-list-logo');
        activeLevel2Item.dataset.sliderChecked = '1';
        if (sliderContainer) {
          initializeSlider(activeLevel2Item,
            addEventListener,
            {
              containerSelector: '.mm-slider-container',
              notMMSlides: false,
            });
        }
      }
    }

    const observerCallback = (entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          initSliderMegaMenu();
        }
      }
    };

    const observer = new IntersectionObserver(observerCallback);
    observer.observe(element);

    if (!isMobileBreakpoint()) {
      activeMenuNavButton?.classList.add(css.classNames.active);
      activeLevel2Item?.classList.remove(css.classNames.hide);
    }

    function showOnMobile(buttonElement, index) {
      buttonElement.classList.toggle(openClassName);
      forEach(level2Buttons, showLevel2MenuInMobile);

      forEach(
        menuNavButtons,
        (el) => {
          if (buttonElement !== el) {
            el.classList.toggle(css.classNames.hide);
          }
        },
      );
      buttonElement.closest(level1ItemSelector).querySelector(level1ButtonPopupSelector)
        .classList.toggle(closeClassName, buttonElement.classList.contains(openClassName));

      element.classList.toggle(css.classNames.expanded);
      menuItems[index].classList.toggle(css.classNames.hide);
    }

    function showOnDesktop(buttonElement, index) {
      activeMenuNavButton.classList.remove(css.classNames.active);
      activeLevel2Item.classList.add(css.classNames.hide);
      buttonElement.classList.add(css.classNames.active);
      menuItems[index].classList.remove(css.classNames.hide);
      activeMenuNavButton = buttonElement;
      activeLevel2Item = menuItems[index];

      initSliderMegaMenu();
    }

    forEach(
      menuNavButtons,
      (button, i) => {
        const debouncedItem = debounce(() => showOnDesktop(button, i), 200);

        isMobileBreakpoint()
          ? addEventListener(button, 'click', () => showOnMobile(button, i))
          : addEventListener(button, 'mouseover', () => debouncedItem());

        if (!isMobileBreakpoint()) {
          addEventListener(button, 'mouseout', () => debouncedItem.cancel());
          addEventListener(button, 'focus', () => debouncedItem());
        }
      },
    );

    function onWindowResize() {
      activeMenuNavButton?.classList.toggle(css.classNames.active, !isMobileBreakpoint());
      if (!isHeaderVersion8()) activeLevel2Item?.classList.toggle(css.classNames.hide, isMobileBreakpoint());

      const openedBtnIdx = Array.from(menuNavButtons).findIndex((btn) => btn.classList.contains(openClassName));

      if (isMobileBreakpoint()) {
        activeMenuNavButton.classList.add(css.classNames.active);
        menuNavButtons[openedBtnIdx]?.classList.remove(css.classNames.active);
      } else if (menuNavButtons[openedBtnIdx]) {
        forEach(level2Buttons, showLevel2MenuInMobile);
        forEach(menuNavButtons, ({ classList }) => classList.remove(css.classNames.hide));

        menuNavButtons[openedBtnIdx].closest(level1ItemSelector).querySelector(level1ButtonPopupSelector)
          .classList.remove(closeClassName);

        element.classList.remove(css.classNames.expanded);
        menuItems[openedBtnIdx]?.classList.toggle(css.classNames.hide);
        menuNavButtons[openedBtnIdx].classList.remove(openClassName);
      }
    }

    addEventListener(window, 'resize', onWindowResize);
  });
});
